<template>
  <div class="about">
    <h1>Info z remote servra</h1>
    <ul>
      <li v-for="(itemInfo,index) in info" :key="index">
        <strong>Kod</strong>: {{ itemInfo.code }}
        <strong>Rate</strong>: {{ itemInfo.rate }}
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "About",
  components: {
  },
  data () {
    return {
      info: '',
    }
  },
  mounted () {
    axios
        .get('https://api.coindesk.com/v1/bpi/currentprice.json')
        // .get(process.env.VUE_APP_API_BASE_URL)
        .then((response) => {
          let data = response.data
          this.info = data.bpi
        })
  }
};
</script>
